.collections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  @media screen and (max-width: 767px) {
    display: flex;
    grid-gap: 0;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -20px;
    margin-right: -20px;
    box-sizing: border-box;
    scrollbar-width: none;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
}
.collection {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 70%;

  &:nth-child(3) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    padding-bottom: calc(35% - 2px);
  }
  &:nth-child(4) {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
    padding-bottom: 71%;
  }

  @media screen and (max-width: 767px) {
    display: inline-block;
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    height: 100%;
    padding: 0 4px !important;
    scroll-snap-align: center;
  }
  @media screen and (max-width: 576px) {
    width: 75%;
    min-width: 75%;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.collectionContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    position: relative;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    max-height: 144px;
    border-radius: 0 0 8px 8px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 10;
  }
}
.collectionImageBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  &.mobile {
    display: none;
    height: 148px;

    @media screen and (max-width: 767px) {
      display: flex;
    }

    @media screen and (max-width: 576px) {
      min-height: 41.1vw;
      max-height: 41.1vw;
    }
  }
}

.collectionTextBlock {
  position: absolute;
  left: 0;
  bottom: 32px;
  padding: 0 32px;
  z-index: 15;

  @media screen and (max-width: 767px) {
    padding: 0 24px;
    bottom: 20px;
  }
}
.collectionTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 1;
  }
}
.collectionDescription {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #fff;

  @media screen and (max-width: 767px) {
    display: none;
  }
}
