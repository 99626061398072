@import '../../global/styles/typography.scss';

.page>section+section {
  margin-top: 100px;
}

.page>.bannersBlock+section {
  margin-top: 100px;

  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
}

.sectionTitle {
  margin: 0 0 32px;
  font-size: 36px;
  font-weight: 600;
  line-height: initial;
}

.sectionSubTitle {
  margin-top: -16px;
  font-size: 16px;
  line-height: 16px;
  color: #808080;
}

.newGoodsBlock {
  div>span:nth-child(3n-2) {
    font-size: $smallMobileFontSize;

    @media screen and (max-width: 1615px) {
      font-size: 10px;
    }

    @media screen and (max-width: 1441px) {
      font-size: 14px;
    }

    @media screen and (max-width: 771px) {
      font-size: $smallMobileFontSize;
    }

    @media screen and (max-width: 687px) {
      font-size: 8px;
    }

    @media screen and (max-width: 350px) {
      font-size: 7px;
    }

    @media screen and (max-width: 304px) {
      font-size: 6px;
    }
  }

  @media screen and (min-width: 1441px) {
    >div>div:not([data-modal]) {
      max-width: 16.5%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .page>section+section {
    margin-top: 50px;
  }

  .sectionTitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 576px) {
  .sectionTitle {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 16px;
  }
}

@media screen and (max-width: 767px) {
  .blogBlock {
    display: none;
  }
}

.homePage {
  composes: page;
  overflow: hidden;
}