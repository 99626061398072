.blog {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 16px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.post {
  &:hover {
    .postTitle {
      color: var(--primary-color, #af1ebe);
    }
  }
}
.postLink {
  display: block;
}
.postImage {
  display: flex;

  span,
  img {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    vertical-align: middle;
  }
}
.postTitle {
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  line-height: initial;
  font-weight: 500;
  transition: color 150ms;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    margin-top: 8px;
  }
}
.postDate {
  margin-top: 8px;

  @media screen and (max-width: 979px) {
    font-size: 12px;
  }
}
