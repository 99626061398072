.banners {
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 979px) {
    flex-direction: column;
  }
}

.mainBanner {
  flex: 1;
  width: calc(100% - 392px);
  border-radius: 8px;
  
  -webkit-border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 979px) {
    width: 100%;
  }
}

.swiper {
  @media screen and (max-width: 979px) {
    overflow: visible;
  }
}

.mainSlide {
  border-radius: 8px;
  overflow: hidden;
}

.slide {
  display: flex;
  aspect-ratio: 1257/559;
  
  
  @media screen and (max-width: 767px) {
    display: none;
  }
  
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    vertical-align: middle;
  }
}

.slideMobile {
  display: none;
  height: 65vw;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.slideImage {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.subBannersBlock {
  margin-left: 16px;
  width: 25%;

  @media screen and (max-width: 979px) {
    margin-left: 0px;
    margin-top: 20px;
    width: 92%;
   
  }
}

.subBanners {
  
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media screen and (max-width: 979px) {
    display: none;
  }
}

.subBannersMobile {
  display: none;

  @media screen and (max-width: 979px) {
    display: block;
  }
}

.subBanner {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 8/3.19;
  border-radius: 8px;

  @media screen and (max-width: 979px) {
    height: 250px;
  }

  @media screen and (max-width: 576px) {
    height: 33.4vw;
  }

  + .subBanner {
    margin-top: 15px;
  }

  img {
    border-radius: 8px;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}

.subBannerContent {
  position: absolute;
  top: 25px;
  left: 15px;
  word-wrap: break-word;
}

.subBannerTitle {
  margin: 0;

  color: #fff;
  font-size: 20px;
  line-height: initial;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.subBannerDecription {
  margin-top: 6px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.mainBannerContent {
  position: absolute;
  bottom: 40px;
  left: 60px;
  word-wrap: break-word;

  @media screen and (max-width: 767px) {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

.mainBannerTitle {
  margin: 0;

  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.mainBannerDecription {
  margin-top: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}
